<template>
<div class="page">
    <h1>信息填写</h1>
    <div class="input-wrp">
        <label for="name">学生姓名</label>
        <input type="text" id="name" v-model="name" placeholder="请填写学生的姓名">
    </div>
    <div class="input-wrp">
        <label for="phone">手机号码</label>
        <input type="text" id="phone" v-model="phone" placeholder="请填写家长的手机号码">
    </div>
    <div class="input-wrp">
        <label for="certno">身份证号</label>
        <input type="text" id="certno" v-model="certno" placeholder="请填写学生的身份证件号码">
    </div>

    <a class="btn-send" href="javascript:void(0)" @click="sendData">确定</a>

    <div class="tipsInfo">
        <div>
            2022年医保发票在线申领服务时间安排如下：<br />
            1、2022年度城乡居民基本医疗保险（在校学生社保580元）发票开始可以开具。<br />
            2、平台将根据提交的顺序，将陆续为各位家长开具发送电子发票。<br />
            3、电子发票将以“微信模板信息”的方式发送。敬请关注并留意“保险微在线”推送的信息。<br />
            4、如有疑问，请添加客服企业微信咨询。
        </div>
        <div class="ercode">
            <img src="../assets/wzxercode.png" show-menu-by-longpress />
        </div>
    </div>
    <NorNotice :msg="conts"  blk="block"/>
    <NavBottom />

</div>
</template>

<script>
import NorNotice from '../components/NorNotice.vue'
export default {
    data() {
        return {
            name: '',
            phone: '',
            certno: '',
            conts:`抱歉，我们暂时无法提供发票。如有需要请您自行前往办理。带上身份证和户口本到税务大厅打印，在参保地的国税窗口办理。`
        }
    },
    components: {
        NorNotice
    },
    methods: {
        sendData() {
            let that = this
            return false
            if (this.name == '' || this.phone == '' || this.certno == '') {
                alert("尚有未填写的信息")
                return false
            }

            if (this.phone.length != 11) {

                alert("请填写正确的手机号码")
                return false
            }
            if (this.certno.length != 18) {

                alert("请填写正确的身份证号码")

                return false
            }

            alert("抱歉，当前暂时无法申领")

            return false

            this.wpost({
                url: '/Ajax/WordProduct/addinvoicereceive',
                data: {
                    name: this.name,
                    phone: this.phone,
                    certno: this.certno,
                },
                succ: (data) => {
                    console.log("result:", data)
                    alert("已成功发送申请")
                    this.$router.push("/")

                }
            })
        }
    }
}
</script>

<style scoped>
.page {
    padding: 5px;
    background: url(../assets/invoiceBg.jpg) no-repeat center top / 100% auto;
    min-height: 100vh;
    background-color: #2859fc;
}

.ercode {
    margin: 20px 0;
    text-align: center;
}

.ercode img {
    width: 37%;
}

.tipsInfo {
    color: #fff;
    padding: 16px;
    font-size: 14px;
}

.btn-send,
.input-wrp {
    width: 90%;
    margin: 12px auto;
    background-color: #fff;
    line-height: 40px;
    border-radius: 12px
}

.input-wrp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
}

.input-wrp input {
    outline: none;
    border: none;
    line-height: 40px;
    width: calc(100% - 80px)
}

.input-wrp label {
    display: block;
    font-size: 12px;
    width: 80px;
    text-align: center;

}

.btn-send {
    display: block;
    text-align: center;
    margin-top: 37px;
    color: #fff;
    background-image: linear-gradient(to right, #a6f0f8, #07d3e4)
}

h1 {
    text-align: center;
    color: #fff;
    padding: 8vw
}
</style>
