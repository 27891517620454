<template>
    <div class="msg-cont" :style="{display:blk}">
        <p>{{msg}}</p>        
    </div>
</template>

<script>
    export default {
        props: {
            msg:{
                type: String,
                required: true,
                default: "此处为信息提示框内容",
            },
            blk:{
                type: String,
                required: true,
                default: "none",
            },
        }
    }
</script>

<style scoped>
.msg-cont{
    display:none;
    transition: all 300ms ease;    
    z-index: 99;
    position: fixed;
    bottom: 87px;
    left: 22px;
    right: 22px;
}
.msg-cont p{
    display: inline-block;
    line-height: 1.7;
    text-align: center;
    font-size: 13px;
    padding: 6px 16px;
    color: #fff;
    border-radius: 8px;
    background-color:rgba(0,0,0, 0.6);

}
</style>